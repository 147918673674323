<template>

  <div>
     <cookie-law :position="'top'" theme="dark-lime"></cookie-law>
      <div class="bg-white py-10 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-12">
  <div class="relative max-w-2xl mx-auto">
    <div>
      <div class="text-center">
      <h1 class="text-5xl font-bold">Get your ticket for the Dornbirn-GFC 2021</h1>
      
      <div class="bg-brand-light text-white p-4 font-bold mt-12">
      <p class="my-4">Thanks for your interest. Follow the process to buy a ticket</p>
      <p class="my-4">If any issues appear regarding the buying process do not hesitate to contact:</p>
      <p class="my-4">Sonja Spöcker</p>
      <a class="my-4" href="tel:+43 664 6216703">Office: +43 664 6216703</a><br>
      <a class="my-4" href="mailto:office@dornbirn-gfc.com">E-Mail: office@dornbirn-gfc.com</a>

      </div>
      </div>
      <div class="bg-brand-grey p-12 mt-12">
      <Stepper v-on:showCountry="showCountry()" v-on:showTicket="showTicket()" v-on:showOverview="showOverview()" :steps="steps"/>
      <div v-if="country" class="mt-12">
        <div class="">
          <div class="">
        <h2 class="font-bold">Country</h2>
        <p>For billing purpose please select you residental country.</p>
        </div>
        <div class="mt-2">
          <dropdown class="my-dropdown-toggle bg-white"
            :options="arrayOfObjects" 
            :selected="object" 
            v-on:updateOption="setCountry" 
            :placeholder="'Select an Item'">
          </dropdown>

        </div>
        </div>
        <h2 class="font-bold mt-4">Your chosen country:</h2>
         <p>{{order.country}}</p>
         <h2 class="font-bold mt-4"> Price </h2>
        <p v-if="individualTax">{{individualTax.netto}} € - {{individualTax.vat}}</p>
        <button v-on:click="showTicket()" class="mt-6 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light sm:w-auto" >Next</button>
      </div>
      
      <div v-if="ticket" class="mt-12">
      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Fill in data
      </h2>
      <p class="mt-4 text-lg leading-6 text-gray-500">
        Choose your ticket type and fill in your information to personalize your ticket.
      </p>
    <div class="chose-ticket mt-12">
      <h2 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
        Chose ticket
      </h2>
    </div>
    <fieldset>
    <legend class="sr-only">
        Server size
    </legend>
    <div>
      <label for="first_name" class="block text-sm font-medium text-gray-700">Dicsount code</label>
    <div class="mt-1">
      <input type="text" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md" v-model="code"/>
      <button @click="getDiscount()" class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand-light hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light mt-2">Apply</button>
    </div>
  </div>
    <div class="space-y-4 mt-12">
        <label class="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-gray-500">
        <input type="radio" name="server_size" value="Hobby" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
        <div class="flex items-center" @click="toggleChecks('isChecked1')">
            <div class="text-sm">
            <p id="server-size-0-label" class="font-medium text-gray-900">
                Dornbirn congress 2021 normal visitor
            </p>
            <div id="server-size-0-description-0" class="text-gray-500">
                <p class="sm:inline"></p>
                <span class="hidden sm:inline sm:mx-1" aria-hidden="true">&middot;</span>
                <p class="sm:inline">15.09. - 17.09.2021 </p>
            </div>
            </div>
        </div>
        <div id="server-size-0-description-1" class="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
            <div class="font-medium text-gray-900">{{individualTax.netto}} € - {{individualTax.vat}}</div>
        </div>
        <!-- Checked: "border-brand-light", Not Checked: "border-transparent" -->
        <div v-bind:class="{ 'border-brand-light': isChecked1 }" class="border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
        </label>
    </div>
    </fieldset>
    
    <div class="mt-12">
      <div v-if="errors.length > 0">
        <div v-for="error in errors" :key="error">
          <p class="text-red-600">{{error}}</p>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div>
          <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
          <div class="mt-1">
            <input v-model="order.buyer.firstName" type="text" name="first_name" id="first_name" autocomplete="given-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
          <div class="mt-1">
            <input v-model="order.buyer.lastName" type="text" name="last_name" id="last_name" autocomplete="family-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="company" class="block text-sm font-medium text-gray-700">Company</label>
          <div class="mt-1">
            <input v-model="order.buyer.company" type="text" name="company" id="company" autocomplete="organization" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="ust" class="block text-sm font-medium text-gray-700">Tax ID</label>
          <div class="mt-1">
            <input v-model="order.buyer.ust" type="text" name="ust" id="ust" autocomplete="organization" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <div class="mt-1">
            <input v-model="order.buyer.email" id="email" name="email" type="email" autocomplete="email" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <label for="street" class="block text-sm font-medium text-gray-700">Street</label>
          <div class="mt-1">
            <input v-model="order.buyer.street" type="text" name="street" id="street" autocomplete="given-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <label for="housenumber" class="block text-sm font-medium text-gray-700">Housenumber</label>
          <div class="mt-1">
            <input v-model="order.buyer.housenumber" type="text" name="housenumber" id="housenumber" autocomplete="family-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <label for="zip" class="block text-sm font-medium text-gray-700">zip</label>
          <div class="mt-1">
            <input v-model="order.buyer.zip" type="text" name="zip" id="zip" autocomplete="given-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <div>
          <label for="city" class="block text-sm font-medium text-gray-700">city</label>
          <div class="mt-1">
            <input v-model="order.buyer.city" type="text" name="city" id="city" autocomplete="family-name" class="py-3 px-4 block w-full shadow-sm focus:ring-brand-light focus:border-brand-light border-gray-300 rounded-md">
          </div>
        </div>
        <p v-if="errorPrivacy" class="text-red-600">Please confirm that you have read and agree to the privacy policy.</p>

        <div class="sm:col-span-2">
          
          <div class="flex items-start">
            <div class="flex-shrink-0">

              <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
              <button v-bind:class="{'bg-brand-light': ppAgree}" type="button" @click="agreePrivacy()" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light" role="switch" aria-checked="false">
                <span class="sr-only">Agree to policies</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span v-bind:class="{'translate-x-5': ppAgree}" aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
            <div class="ml-3">
              <p class="text-base text-gray-500">
                By selecting this, you agree to the
                <a href="#" class="font-medium text-gray-700 underline">Privacy Policy</a>
                and
                <a href="#" class="font-medium text-gray-700 underline">Cookie Policy</a>.
              </p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <button @click="showOverview()" class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand-light hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light">
            Next
          </button>
        </div>
      </div>
      </div>
      </div>
      <div v-if="overview" class="mt-12">
        <h2 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
            Overview of your order
        </h2>
        <h2 class="font-bold mt-4">Selected Ticket</h2>
        <p>{{product.name}} </p>
        <h2 class="font-bold mt-4">Price</h2>
        {{individualTax.netto}} € - {{individualTax.vat}}
        <!-- <p v-if="product.id == 1">{{priceNormal}}</p>
        <p v-if="product.id == 2">{{priceInst}}</p> -->
        <h2 class="font-bold mt-4">First Name</h2>
        <p>{{order.buyer.firstName}}</p>
        <h2 class="font-bold mt-4">Last Name</h2>
        <p>{{order.buyer.lastName}}</p>
        <h2 class="font-bold mt-4">Company</h2>
        <p>{{order.buyer.company}}</p>
        <h2 class="font-bold mt-4">Address</h2>
        <p>{{order.buyer.street}} {{order.buyer.housenumber}}</p>
        <p>{{order.buyer.zip}} {{order.buyer.city}}</p>
        <div class="mt-6">
        <button v-if="!ordered && !loading" @click="showTicket()" class="mr-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand-light hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light">
            Edit
          </button>
        <button v-if="!ordered && !loading" @click="finishOrder()" class="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand-light hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-light">
            Order
          </button>
          <p class="mt-6" v-if="loading">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-brand-light" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg></p>
          <p class="text-green-600 mt-6" v-if="success.length > 0">{{success}}</p>
          </div>
      </div>
    </div>
  </div>
</div>
  </div>
  </div>
</template>

<script>
import Stepper from "./../components/Stepper";
import taxes from "./../assets/tax/tax.json";
import firebase from 'firebase/app';
import CookieLaw from 'vue-cookie-law';
import dropdown from 'vue-dropdowns';
export default {
    components: {
        Stepper,
        CookieLaw,
        dropdown
    },
    computed: {
        individualTax() {
        return this.taxes.find(tax => {
            return tax.name === this.order.country;
        });
        },
        priceNormal(){
            return (390 * this.individualTax.tax).toFixed(2);
        },
        priceInst(){
            return (300 * this.individualTax.tax).toFixed(2);
        }
  },
    methods: {
        showCountry(){
            this.ticket=false;
            this.steps[1].status = 'upcoming'
            this.overview = false;
            this.steps[2].status = 'upcoming'
            this.country = true;
            this.steps[0].status = 'current'
        },
        showTicket() {
                this.country = false;
                this.steps[0].status = 'complete'
                this.overview = false;
                this.steps[2].status = 'upcoming'
                this.ticket = true;
                this.steps[1].status = 'current'
        },
        showOverview() {
          this.errorPrivacy = false;
            //validation
            if(this.ppAgree == true) {
              this.errors = [];
              if(this.discount == true && !this.order.buyer.company) {
                this.errors.push('Please enter a company name that validates you for the discount.')
              }
          if (!this.order.buyer.firstName) {
            this.errors.push('Please enter your firstname.')
          }
          if (!this.order.buyer.lastName) {
            this.errors.push('Please enter your lastname.')
          }
          if (!this.order.buyer.email) {
            this.errors.push('Please enter your email.')
          }
          if (!this.order.buyer.street || !this.order.buyer.housenumber || !this.order.buyer.zip || !this.order.buyer.city) {
            this.errors.push('Please enter your address.')
          }
          if (this.order.buyer.company && !this.order.buyer.ust && this.order.country !== 'No-EU') {
            this.errors.push('Please enter your tax ID')
          }
              if(this.errors.length == 0){
            this.country = false;
            this.steps[0].status = 'complete'
            this.ticket = false;
            this.steps[1].status = 'complete'
            this.overview = true;
            this.steps[2].status = 'complete'
            }
          }
          else {
              this.errorPrivacy = true;
              this.country = false;
                this.steps[0].status = 'complete'
                this.overview = false;
                this.steps[2].status = 'upcoming'
                this.ticket = true;
                this.steps[1].status = 'current'
            }
        },
        setCountry({ name }) {
            this.order.country = name
        },
        setProduct(){
          this.product = this.individualTax;
        },
        toggleChecks(variableName) {
            if (variableName == 'isChecked1'){
                this.setProduct()
                this.isChecked1 = true;
                this.isChecked2 = false;
            }
        },
        agreePrivacy(){
            this.ppAgree = !this.ppAgree
        },
        finishOrder(){
            this.setProduct();
            this.order.product = this.product;
            console.log(this.order);
            this.loading = true;
            firebase.firestore().collection('orders').add(this.order).then(() => {
              var addMessage = firebase.functions().httpsCallable('placeOrder');
            addMessage(this.order)
            .then((result) => {
              this.loading = false;
              this.ordered = true;
              this.success = "Successfully ordered your ticket. Check your inbox mail to see the confirmation of you order."
                // Read result of the Cloud Function.
                var sanitizedMessage = result;
                console.log(sanitizedMessage)
            });
            }).catch(err => {
              console.log(err)
              this.success = "Something went wrong, please try again or contact the event host"
            })

          
        },
        getDiscount() {
          if(this.code == 'XRBWIUPONDC'){
            this.discount = true;
            if (this.order.country == 'Austria') {
              this.order.country = "Austria Discount"
            } else if (this.order.country == "EU (except Austria)"){
              this.order.country = "EU (except Austria) Discount"
            } else if (this.order.country == "No-EU") {
              this.order.country = "No-EU Discount"
              
            this.setProduct()
          }
        } else if (this.code == 'zuPxcewQkdeFM') {
          this.order.country = "Institutional"
          this.setProduct()
        }
      }
    },
    data() {
        return {
            taxes: taxes,
            ordered: false,
            loading: false,
            success: "",
            errors: [],
            country: true,
            ticket: false,
            overview: false,
            product: {},
            order: {
                country: "",
                iso2: "",
                buyer: {
                  company: "",
                  ust: ""
                },
                product: {},
                price: null,
            },
            isChecked1: true,
            ppAgree: false,
            steps: [
              { id: '1', name: 'Country', href: '#', status: 'current' },
              { id: '2', name: 'Ticket', href: '#', status: 'upcoming' },
              { id: '3', name: 'Overview', href: '#', status: 'upcoming' },
            ],
            arrayOfObjects: [
              {
                name: 'Austria'
              },
              {
                name: 'EU (except Austria)'
              },
              {
                name: 'No-EU'
              }
            ],
            object: {
              name: 'Country',
            },
            discount: false,
            code: "",
            errorPrivacy: false
        }
    },

}
</script>

<style>
  .my-dropdown-toggle {
  border-radius: 5px;
 
  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }
 
  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
</style>