<template>
<div class="">
  <nav aria-label="Progress">
    <ol class="space-y-4 md:flex md:space-y-0 md:space-x-8">
      <li v-for="step in steps" :key="step.name" class="md:flex-1">
        <button  @click="emitEvent(step.id)" v-if="step.status === 'complete'" class="w-full group pl-4 py-2 flex flex-col border-l-4 border-brand-light hover:border-brand-light md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
          <span class="text-xs text-brand-light font-semibold tracking-wide uppercase group-hover:text-brand-light">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </button>
        <button @click="emitEvent(step.id)" v-else-if="step.status === 'current'" class="w-full pl-4 py-2 flex flex-col border-l-4 border-brand-light md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
          <span class="text-xs text-brand-light font-semibold tracking-wide uppercase">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </button>
        <button @click="emitEvent(step.id)" v-else class="w-full group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
          <span class="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">{{ step.id }}</span>
          <span class="text-sm font-medium">{{ step.name }}</span>
        </button>
      </li>
    </ol>
  </nav>

<!-- <div>
    <button class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto" @click="showCountry()">Country</button>
    <button class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto" @click="showTicket()">Ticket</button>
    <button class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto" @click="showOverview()">Overview</button>
</div> -->
</div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array
        },
    },
    methods: {
        emitEvent(id) {
            console.log(id)
            switch (parseInt(id)) {
                case 1:
                    this.showCountry()
                    break;
                case 2:
                    this.showTicket()
                    break;
                case 3:
                    this.showOverview()
                    break
                default:
                    break;
            }
        },
        showCountry() {
            console.log('showCountry')
            this.$emit("showCountry")
        },
        showTicket(){
            this.$emit("showTicket")
        },
        showOverview() {
            this.$emit("showOverview")
        }
    },
    data:() => ({
    })

}
</script>

<style>

</style>